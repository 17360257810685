import * as qrcode from "qrcode";
import { useRef, useState } from "react";
import styled from "styled-components";

const SCALE = 10;

type ErrorCorrectionLevel = "L" | "M" | "Q" | "H";
type MaskPattern =  0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
type SegmentMode = "alphanumeric" | "numeric" | "byte";
type Segment = { data: string; mode:SegmentMode };

function getQRCode(
  data: string | Segment[], 
  version: number | undefined, 
  errorCorrectionLevel: ErrorCorrectionLevel, 
  maskPattern: MaskPattern | undefined
) {
  try {
    return { qrCode: qrcode.create(data, {
      version,
      errorCorrectionLevel,
      maskPattern
    })};
  } catch (error) {
    return { error: `${error}` }
  }
}

export const App: React.FC = () => {
  const [text, setText] = useState("");
  const [version, setVersion] = useState<number | undefined>(undefined);
  const [
    errorCorrectionLevel, 
    setErrorCorrectionLevel
  ] = useState<ErrorCorrectionLevel>("M");
  const [maskPattern, setMaskPattern] = useState<MaskPattern | undefined>(undefined);
  const [segments, setSegments] = useState<Segment[] | undefined>(undefined);
  const svgElement = useRef<SVGSVGElement>(null);

  const [qrCode, setQRCode] = useState<qrcode.QRCode>();
  const [error, setError] = useState<string | undefined>();

  return (
    <Main>
      <h1>QR Coder</h1>
      <p>The No-Nonsense QR Code Generator</p>
      <FormRow>
        <label>Segments</label>
        <Select value={segments === undefined ? "auto" : "manual"} onChange={(event) => {
          const value = event.target.value;
          if (value === "auto") {
            setSegments(undefined);
          } else {
            setSegments([{ data: text, mode: "alphanumeric" }]);
          }
        }}>
          <option value="auto">Auto</option>
          <option value="manual">Manual</option>
        </Select>
      </FormRow>
      { segments ? <>
        { segments.map((segment, i) => {
          return <FormRow key={i}>
            <TextArea
              rows={5}
              value={segment.data}
              placeholder="Enter some text..."
              onChange={(event) => {
                setSegments(segments.map((seg, j) => {
                  return j === i ? { ...segment, data: event.target.value } : seg;
                }));
              }}
            />
            <Select value={segment.mode} onChange={(event) => {
                setSegments(segments.map((seg, j) => {
                  return j === i ? { 
                    ...segment, 
                    mode: event.target.value as SegmentMode 
                  } : seg;
                }));
            }}>
              <option value="alphanumeric">Alphanumeric</option>
              <option value="numeric">Numeric</option>
              <option value="byte">Byte</option>
            </Select>
            {/* <button 
              onClick={() => {
                setSegments(segments.flatMap((seg, j) => {
                  return j === i ? [] : [seg];
                }));
              }}
              disabled={segments.length === 1}
            >Delete</button> */}
          </FormRow>;
        })}
        <FormRow>
          <Button
            onClick={() => {
              setSegments([...segments, { data: "", mode: "alphanumeric" }]);
            }}
          >Add Segment</Button>
          <Button
            onClick={() => {
              setSegments(segments.slice(0, -1));
            }}
            disabled={segments.length === 1}
          >Remove Segment</Button>
        </FormRow>
      </> : 
      <FormRow>
        <TextArea
          rows={5}
          placeholder="Enter some text..."
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      </FormRow>}

      <FormRow>
        <label>Version</label>
        <Description>Bigger is better. Well, if better is "can hold more data." If you pick 40, expect the browser to lag.</Description>
        <Select value={version} onChange={(event) => {
          const value = event.target.value;
          setVersion(value ? parseInt(value) : undefined);
        }}>
          <option key="auto" value={undefined}>Auto</option>
          { Array.from({ length: 40 }).map((_, i) => {
            return <option key={i} value={i + 1}>{i + 1}</option>;
          })};
        </Select>
      </FormRow>

      <FormRow>
        <label>Error Correction Level</label>
        <Description>The higher the error correction level, the more of the QR Code can be obscured. Quartile is in between Medium and High.</Description>
        <Select value={errorCorrectionLevel} onChange={(event) => {
          setErrorCorrectionLevel(event.target.value as ErrorCorrectionLevel);
        }}>
          <option value="L">Low</option>
          <option value="M">Medium</option>
          <option value="Q">Quartile</option>
          <option value="H">High</option>
        </Select>
      </FormRow>

      <FormRow>
        <label>Mask Pattern</label>
        <Description>Magic that changes the way the QR Code looks. For more information <Link href="https://letmegooglethat.com/?q=qr+code+mask+pattern">here</Link>.</Description>
        <Select value={maskPattern} onChange={(event) => {
          const value = event.target.value;
          setMaskPattern(value ? parseInt(value) as MaskPattern : undefined);
        }}>
          <option key="auto" value={undefined}>Auto</option>
          { Array.from({ length: 8 }).map((_, i) => {
            return <option key={i} value={i}>{i}</option>;
          })};
        </Select>
      </FormRow>
      <FormRow>
        <Button
          onClick={async () => {
            const { qrCode, error } = getQRCode(
              segments ?? text,
              version,
              errorCorrectionLevel,
              maskPattern
            );
            setQRCode(qrCode);
            setError(error);
          }}
          disabled={!text && !segments}
        >Generate QR Code</Button>
      </FormRow>
      { (segments ?? text) && error && <FormRow>{error}</FormRow> }
      <FormRow>
        {qrCode && <svg 
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          ref={svgElement}
          viewBox={`0 0 ${qrCode.modules.size * SCALE} ${qrCode.modules.size * SCALE}`}
        >
          {Array.from({ length: qrCode.modules.size }).flatMap((_, i) => {
            return Array.from({ length: qrCode.modules.size }).map((_, j) => {
              return <rect 
                key={`${i}/${j}`}
                id={`${i}/${j}`}
                x={i * SCALE} 
                y={j * SCALE} 
                width={SCALE} 
                height={SCALE} 
                fill={qrCode.modules.get(i, j) ? "black" : "white"}
              />;
            })
          })}
        </svg>}
      </FormRow>

      <FormRow>
        <Button
          onClick={() => {
            download("qr.svg", svgElement.current?.outerHTML || "");
            setError(undefined);
          }}
          disabled={!qrCode}
        >Download SVG</Button>
        { false && <Button
          onClick={async () => {
            try {
              const data = await qrcode.toDataURL(segments ?? text, {
                version,
                errorCorrectionLevel,
                maskPattern
              });
              downloadDataURL("qr.png", data || "");
              setError(undefined);
            } catch (error) {
              setError(`${error}`);
            }
          }}
          disabled={!qrCode}
        >Download PNG</Button>}
      </FormRow>
      { qrCode && <FormRow>
        <span>{"Error Correction Level: " + [
          "Medium",
          "Low",
          "High",
          "Quartile",
        ][qrCode.errorCorrectionLevel.bit]}</span>
        <span>{"Version: " + qrCode.version}</span>
        <span>{"Mask Pattern: " + qrCode.maskPattern}</span>  
        {qrCode.segments.map((segment, index) => {
          const str = typeof segment.data === "string"
            ? segment.data
            : new TextDecoder("utf-8").decode(segment.data);
          return <span key={index}>{segment.mode.id + ": " + str}</span>
        })}
      </FormRow>}
    </Main>
  );
}

// From https://stackoverflow.com/a/18197341
function download(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function downloadDataURL(filename: string, dataURL: string) {
  var element = document.createElement('a');
  element.setAttribute('href', dataURL);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const Main = styled.div`
  padding: 10px;
  padding-bottom: 30px;
  max-width: 600px;
`;

const Link = styled.a`
  color: inherit;
  text-decoration: underline;

  &:visited {
    color: inherit;
  }
`;

const TextArea = styled.textarea`
  resize: vertical;
  min-height: 5em;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  font-family: inherit;

  &:active, &:focus {
    border: 1px solid #60aacd;
    outline: none;
  }
`;

const Description = styled.div`
  color: gray;
  font-size: 13px;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 5px;
`;

const Button = styled.button`
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
  cursor: pointer;

  &:disabled {
    cursor: unset;
  }
`;

const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: black;
  outline: none;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
  cursor: pointer;
  background-position: center right 7px;
  background-size: 10px;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAACFklEQVR4nO3ZTahMYRzH8Y+3vNW1QFwLlGy8ZKEsZKEkRbKzsFEs7oIVSYqyUxZKNndhqWwUCwtZyoJYyUapiwXlKkaE6xoW49S9ZqY5Z+Y5zzmN51u/xZzTqd/339NTzzMkEolEIpFIJBKJxH/InH9+r8OKKopEZBJvOr04hyZ+D3maOJ1Jz1wBDYzkGeEQ8AErYe6Mhx2XxZDyutPD7fii+iVadj5jW7fJ7Md0DUqWlWkc7CafMVaDomXlRC/5jKs1KBs6l/PK09ocb9egdKjcMnvDz8ViPKpB+UHzBEuLymeM4lUNJPrNBFb1K5+xCR9rIFM0DWwZVD5jN37UQCpvprAnlHzGsRqI5UkTR0PLZ1yqgWCvXCxLntbh6UYNJLvlpvYjfnAW4WHFop3yAAtL9J7FcrwoWahIXqrgImcD3g9YPEQmsbFk167swvceBcvMN+ws3bIHh1VzldbEkQh+uTgv/gDORDErwLh48tcjORViAe4rX/4e5kdyKswInilP/jmWRbPpk/V4J7z8W6yN6DEQoW+Yv2JHVIMAHBDmhnkahyJ3D8Ypgw/gZPTWgbmmf/krFfQNzjzcUVz+7t9vh4IleCy//FMD3OTWlVGtPyV7yU9gdUUdS2czPuku38DWytpFYh9+apefwt4Ke0XluPYBjFXaqAIuaJ3pf+FsxV0qY40h3vASiUQikUgkEolEorb8AZuuMQ3FGRXaAAAAAElFTkSuQmCC);

  &:active, &:focus {
    border: 1px solid #60aacd;
    outline: none;
  }
`;